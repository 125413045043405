
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Link from "next/link";
import { useRouter } from "next/router";
import { loginGuest } from "../ducks/user";
import Spinner from "../components/Spinner";
import styles from "./styles/Home.module.css";
import { FaUser } from "react-icons/fa";
// import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useState } from "react";
import Head from "next/head";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useSiteData } from "../ducks/siteData";
import { useUser } from "../ducks/user";
import { useSignIn } from "../ducks/signIn";
// import FrontPagePuzzle from "../modules/puzzle/FrontPagePuzzle";
import { hasBadWord } from "../util/badWords";
export default function Home() {
    const { t } = useTranslation("common");
    const { t: homeT } = useTranslation("home");
    const { playersOnline, loadingBarVisible } = useSiteData();
    const { isDefaultGuest } = useUser();
    const { loading } = useSignIn();
    const [username, setUsername] = useState("");
    const [error, setError] = useState(false);
    const router = useRouter();
    function doSubmit() {
        const name = username.trim();
        if (name === "" || !name || name.length > 18) {
            setError(true);
        }
        else {
            import("washyourmouthoutwithsoap").then((wash) => {
                if (wash.check("en", name) ||
                    wash.check(router.locale, name) ||
                    hasBadWord(name)) {
                    setError(true);
                }
                else {
                    setError(false);
                    loginGuest(name);
                    router.push("/lobby");
                }
            });
        }
    }
    function renderPlayersOnline() {
        return (<div className={styles.playersOnlineCont}>
        <div className={styles.playersOnlineNumCont} style={playersOnline ? {} : { marginBottom: "6px" }}>
          <FaUser />
          <div className={styles.playersOnlineNum}>{playersOnline}</div>
        </div>
        <div className={styles.playersOnlineText}>{t("PLAYERS ONLINE")}</div>
      </div>);
    }
    function handleKeyDown(e) {
        if (e.key === "Enter") {
            doSubmit();
        }
    }
    function renderMenu() {
        if (loading || loadingBarVisible) {
            return (<div className={styles.spinner}>
          <Spinner blue={false}/>
        </div>);
        }
        if (!isDefaultGuest) {
            // Signed in as guest or member
            return (<div className={styles.play}>
          <Link className={styles.playLink} href="/lobby">
            <button className={styles.playButton}>{t("Play")}</button>
          </Link>
          {renderPlayersOnline()}
        </div>);
        }
        return (<>
        <div className={styles.createAccount}>
          <Link className={styles.playLink} href="/register">
            <button className={styles.playButton}>
              {t("Create free account")}
            </button>
          </Link>
          {renderPlayersOnline()}
        </div>
        <div className={styles.guestLogin}>
          <div className={styles.line}/>
          <input onFocus={() => setError(false)} className={error
                ? styles.guestInputError + " animate-pulse-slow   "
                : styles.guestInput} type="text" onChange={(e) => setUsername(e.target.value)} onKeyDown={handleKeyDown} value={username} name="username" placeholder={t("Enter your guest name")}/>
          <button className={styles.guestButton} onClick={() => doSubmit()}>
            {t("Play as guest")}
          </button>
        </div>
      </>);
    }
    // const puzzleSection = useFeatureIsOn("puzzle-section");
    // const endSection = useFeatureIsOn("end-section");
    // const newFrontPage = useFeatureIsOn("new-front-page");
    return (<div>
      {/* {!newFrontPage && ( */}
      <div>
        <Head>
          <title>{"Chess Hotel | " + homeT("Play chess online", null)}</title>
          <meta name="description" content={t("description")}/>
          <meta name="keywords" content={t("keywords")}/>
        </Head>
        {/* <div className={styles.ieNoticeInfo}>
            {t("browser out of date")}
            <br />
            <br />
            <a href="http://outdatedbrowser.com">
              <button className={styles.ie11Button}>
                {t("Update your browser")}
              </button>
            </a>
          </div> */}
        {/* <div className={styles.jumbotronBg}> */}
        <div className={styles.jumbotron}>
          <h1 className="mt-5 mb-0 text-3xl font-extrabold text-center text-white md:text-5xl">
            {homeT("header")}
          </h1>
          <div className={styles.underTitle}>{homeT("subHeader")}</div>
          {renderMenu()}
        </div>
        {/* </div> */}
        {/* <div className={styles.jumbotronBottom}></div> */}
        <div className={`${styles.infoCont} bg-white px-2 md:px-0`}>
          <div className={styles.infoSection}>
            <div className={styles.infoItem}>
              <div className={styles.infoImgCont}>
                <Image width="50" height="59" alt="" src={"/images/timer.png"}/>
              </div>
              <h4 className={styles.infoTitle}>{homeT("Live games")}</h4>
              <div className={styles.text}>{homeT("play_live_text")}</div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.infoImgCont}>
                <Image width="57" height="57" alt="" src={"/images/cup.png"}/>
              </div>
              <h4 className={styles.infoTitle}>{homeT("Rating & Stats")}</h4>
              <div className={styles.text}>{homeT("rating_stats_text")}</div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.infoImgCont}>
                <Image width="49" height="65" alt="" src={"/images/hand.png"}/>
              </div>
              <h4 className={styles.infoTitle}>
                {homeT("mobile_friendly_title")}
              </h4>
              <div className={styles.text}>{homeT("mobile_friendly_text")}</div>
            </div>
          </div>
        </div>
        <div className="py-14">
          <div className={`${styles.phoneSection}`}>
            <div className={`${styles.phoneTextCont} space-y-5`}>
              <h2 className="text-3xl font-semibold">
                {homeT("iphone_big_title")}
              </h2>
              <div className={`${styles.phoneText} max-w-lg px-2 md:px-0`}>
                {homeT("iphone_big_text")}
              </div>
              <div className="flex mt-3 space-x-3">
                <a className="hover:opacity-70" href="https://appstore.com/chesshotel">
                  <Image alt="" src={"/images/appstore-badge-us.svg"} width="137" height="42"/>
                </a>
                <a className="hover:opacity-70" href="https://play.google.com/store/apps/details?id=com.foggymedia.ChessHotel">
                  <Image alt="" src={"/images/google-play-badge-en.png"} width="137" height="42"/>
                </a>
              </div>
            </div>
            <div className="w-[80%] md:w-[30%]">
              <Image alt="" src={"/images/iphone-screenshot2.png"} width="385" height="550"/>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
      {/* {newFrontPage && (
          <div>
            <Head>
              <title>{"Chess Hotel | " + homeT("Play chess online", null)}</title>
              <meta name="description" content={t("description")} />
              <meta name="keywords" content={t("keywords")} />
            </Head>
            <div className={styles.ieNoticeInfo}>
              {t("browser out of date")}
              <br />
              <br />
              <a href="http://outdatedbrowser.com">
                <button className={styles.ie11Button}>
                  {t("Update your browser")}
                </button>
              </a>
            </div>
            <div className={styles.jumbotron}>
              <h1 className={styles.title}>{homeT("header")}</h1>
              <div className={styles.underTitle}>{homeT("subHeader")}</div>
              {renderMenu()}
            </div>
            <div className={styles.jumbotronBottom}></div>
            <div className={`${styles.infoCont} bg-white`}>
              <div className={styles.infoSection}>
                <div className={styles.infoItem}>
                  <div className={styles.infoImgCont}>
                    <Image
                      width="50"
                      height="59"
                      alt=""
                      src={"/images/timer.png"}
                    />
                  </div>
                  <h4 className={styles.infoTitle}>{homeT("Live games")}</h4>
                  <div className={styles.text}>{homeT("play_live_text")}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoImgCont}>
                    <Image
                      width="57"
                      height="57"
                      alt=""
                      src={"/images/cup.png"}
                    />
                  </div>
                  <h4 className={styles.infoTitle}>{homeT("Rating & Stats")}</h4>
                  <div className={styles.text}>{homeT("rating_stats_text")}</div>
                </div>
                <div className={styles.infoItem}>
                  <div className={styles.infoImgCont}>
                    <Image
                      width="49"
                      height="65"
                      alt=""
                      src={"/images/hand.png"}
                    />
                  </div>
                  <h4 className={styles.infoTitle}>
                    {homeT("mobile_friendly_title")}
                  </h4>
                  <div className={styles.text}>
                    {homeT("mobile_friendly_text")}
                  </div>
                </div>
              </div>
            </div>
     
            <FrontPagePuzzle />
            <div className="bg-white py-14">
              <div className={`${styles.phoneSection}`}>
                <div className={`${styles.phoneTextCont} space-y-5`}>
                  <h2>{homeT("iphone_big_title")}</h2>
                  <div className={`${styles.phoneText} max-w-lg`}>
                    {homeT("iphone_big_text")}
                  </div>
                  <div className={styles.appButtons}>
                    <a href="https://appstore.com/chesshotel">
                      <Image
                        alt=""
                        src={"/images/appstore-badge-us.svg"}
                        width="137"
                        height="42"
                      />
                    </a>
                  </div>
                </div>
                <div className=" w-[30%]">
                  <Image
                    alt=""
                    src={"/images/iphone-screenshot2.png"}
                    width="385"
                    height="550"
                  />
                </div>
              </div>
            </div>
            <div className=" flex flex-col items-center bg-[#344559]  space-y-5 justify-center py-20">
              <div className={styles.underTitleEnd}>{homeT("master-moves")}</div>
              {isDefaultGuest ? (
                <div className="flex flex-col items-center justify-center space-y-7">
                  <div>
                    <Link className={styles.playLink} href="/register">
                      <button className={styles.playButtonEnd}>
                        {t("Create free account")}
                      </button>
                    </Link>
                  </div>
                  <div className={styles.line} />
  
                  <div className="">
                    <input
                      onFocus={() => setError(false)}
                      className={
                        error
                          ? styles.guestInputErrorEnd + " animate-pulse-slow "
                          : styles.guestInputEnd
                      }
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyDown={handleKeyDown}
                      value={username}
                      name="username"
                      placeholder={t("Enter your guest name")}
                    />
                    <Link className={styles.playLink} href="/lobby">
                      {" "}
                      <button className={styles.guestButtonEnd}>
                        {t("Play as guest")}
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div>
                  <Link className={styles.playLink} href="/lobby">
                    <button className={styles.playButton}>{t("Play")}</button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )} */}
    </div>);
}

    async function __Next_Translate__getStaticProps__1960fac1545__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1960fac1545__ as getStaticProps }
  